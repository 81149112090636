<template>
    <div>
        <v-card>
            <v-toolbar flat>
                <v-card-title>
                    <span class="primary--text">{{$t('caption.transferCase')}}</span>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-btn text fab dark large color="primary" @click="$router.push('/criminal-cases')">
                    <v-icon>{{icons.mdiFormatListBulleted}}</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field outlined dense :label="$t('case.caseNo')" v-model="criminalCase.caseNo" hide-details :rules="rule.requiredField()">

                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field outlined dense :label="$t('case.registerDate')" v-model="criminalCase.caseRegisDate" hide-details type="date" :rules="rule.requiredField()">

                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field outlined dense :label="$t('case.caseDate')" v-model="criminalCase.caseOrigDate" hide-details type="date" :rules="rule.requiredField()">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="7">
                            <v-text-field outlined dense :label="$t('case.facts')" v-model="criminalCase.caseFact" hide-details>

                            </v-text-field>

                        </v-col>
                        <v-col cols="12" md="5">
                            <v-btn small rounded outlined class="mr-5 primary--text" @click="openPlaintiffDialog">
                                <v-icon>{{icons.mdiAttachment}}</v-icon>ភ្ជាប់ដើមបណ្តឹង</v-btn>

                            <v-dialog v-model="plaintiffDialog" width="800">
                                <v-card>
                                    <v-card-title>{{$t('case.addPlaintiff')}}</v-card-title>
                                    <v-card-text>
                                        <div v-for="(item,index) in plaintifList" :key="item.id">
                                            <v-row>
                                                <v-col cols="12" md="4">
                                                    <v-text-field v-model="item.nameKh" outlined hide-details :label="$t('case.plaintiffName')" dense>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="3">
                                                    <v-radio-group row v-model="item.gender" class="mt-0 ml-2">
                                                        <v-radio :label="$t('text.male')" :value=0></v-radio>
                                                        <v-radio :label="$t('text.female')" :value=1></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                                <v-col cols="12" md="3">
                                                    <v-text-field v-model="item.age" outlined hide-details :label="$t('text.age')" dense>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="2">
                                                    <v-icon class="mr-5" large color="primary" @click="removePlaintiff(index)">{{icons.mdiDelete}}</v-icon>
                                                </v-col>
                                            </v-row>

                                        </div>
                                        <v-row class="mt-5 justify-center">
                                            <v-col cols="4" align="center">
                                                <v-btn class="btn primary" @click="addPlaintiff($event)" small rounded>
                                                    <v-icon small>{{icons.mdiPlus}}</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn class="btn primary" @click="plaintiffDialog=false">Close</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-btn small rounded outlined class="mr-5 primary--text" @click="openDefendantDialog">
                                <v-icon>{{icons.mdiAttachment}}</v-icon>ភ្ជាប់ចុងចម្លើយ</v-btn>
                            <v-dialog v-model="defendantDialog" width="800">
                                <v-card>
                                    <v-card-title>{{$t('case.addDefendant')}}</v-card-title>
                                    <v-card-text>
                                        <div v-for="(item,index) in defendantList" :key="item.id">
                                            <v-row>
                                                <v-col cols="12" md="4">
                                                    <v-text-field v-model="item.nameKh" outlined hide-details :label="$t('case.defendantName')" dense>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="3">
                                                    <v-radio-group row v-model="item.gender" class="mt-0 ml-2">
                                                        <v-radio :label="$t('text.male')" :value=0></v-radio>
                                                        <v-radio :label="$t('text.female')" :value=1></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                                <v-col cols="12" md="3">
                                                    <v-text-field v-model="item.age" outlined hide-details :label="$t('text.age')" dense>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="2">
                                                    <v-icon class="mr-5" large color="primary" @click="removePlaintiff(index)">{{icons.mdiDelete}}</v-icon>
                                                </v-col>
                                            </v-row>

                                        </div>
                                        <v-row class="mt-5 justify-center">
                                            <v-col cols="4" align="center">
                                                <v-btn class="btn primary" @click="addDefendant($event)" small rounded>
                                                    <v-icon small>{{icons.mdiPlus}}</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn class="btn primary" @click="defendantDialog=false">Close</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="7">
                            <v-select :items="caseCrimeTypeList" v-model="criminalCharge" item-text="crimeTypeNameKh" return-object label="ជ្រើសរើសបទល្មើសនៃការចោទ" outlined hide-details multiple>
                                <template v-slot:selection="{ item, index }">
                                    <v-chip small>{{ item.crimeTypeNameKh }}</v-chip>
                                </template>
                                <template v-slot:item="{ active, item, attrs, on }">
                                    <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                        <v-list-item-action>
                                            <v-checkbox :input-value="active"></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-row no-gutters align="center">
                                                    <span>{{ item.crimeTypeNameKh }}</span>
                                                </v-row>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn class="btn primary" @click="register">
                    <v-icon left>{{icons.mdiContentSave}}</v-icon> {{$t('button.regisCase')}}</v-btn>
                <!-- $router.push('/criminal-cases/progress') -->
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import {
  mdiAttachment,
  mdiPlus,
  mdiDelete,
  mdiFormatListBulleted,
  mdiContentSave
} from '@mdi/js'
import SystemService from '@/services/service.system'
import ServicePolice from '@/services/service.policeCase'
import CaseService from '@/services/service.case'
import Rule from '@/plugins/rules.js'
import { onBeforeRouteUpdate } from 'vue-router'

export default {
  name: 'add-criminal-case',
  data() {
    return {
      icons: {
        mdiAttachment,
        mdiPlus,
        mdiDelete,
        mdiFormatListBulleted,
        mdiContentSave
      },
      resultMessage: '',
      rule: [],
      criminalCase: {},
      plaintiffDialog: false,
      defendantDialog: false,
      plaintifList: [],
      defendantList: [],
      caseCrimeTypeList: [],
      criminalCharge: []
    }
  },
  async created() {
    this.rule = Rule

    if (this.$route.params.id) {
      var caseId = this.$route.params.id

      await ServicePolice.getCaseById(caseId).then(response => {
        this.policeCase = response.data
        this.criminalCase.caseNo = this.policeCase.caseNo
      })

      await ServicePolice.getChargeByCaseId(caseId).then(response => {
        var chargeList = response.data
        chargeList.forEach(item => {
          this.criminalCharge.push(item.caseCrimeType)
        })
      })

      await ServicePolice.getPartyByCaseId(caseId).then(response => {
        var partyList = response.data
        partyList.forEach(item => {
          if (item.partyRole.roleNameEn == 'Plaintiff') {
            this.plaintifList.push(item)
          } else if (item.partyRole.roleNameEn == 'Defendant') {
            this.defendantList.push(item)
          }
        })
      })
    }
  },
  mounted() {
    SystemService.getCaseCrimeTypeAll().then(response => {
      this.caseCrimeTypeList = response.data
    })
  },
  methods: {
    register() {
      if (!this.$refs.form.validate()) return

      CaseService.insert(this.criminalCase).then(
        response => {
          this.criminalCase = response.data
          if (this.criminalCase) {
            this.resultMessage = 'Case has been registered'
            this.$notify({
              group: 'message',
              title: 'Notification',
              type: 'success',
              text: this.resultMessage
            })

            if (this.criminalCharge.length > 0) {
              var criminalChargeList = []
              this.criminalCharge.forEach((item, index) => {
                criminalChargeList.push({
                  caseCrimeType: item,
                  caseCriminalData: this.criminalCase,
                  proceeding: 1
                })
              })

              CaseService.insertChargeBatch(criminalChargeList)
            }

            if (this.plaintifList.length > 0) {
              this.plaintifList = this.plaintifList.map(v => {
                const { id, ...rest } = v
                return { ...rest, caseId: this.criminalCase.id }
              })

              CaseService.insertPartyBatch(this.plaintifList).then(response => {
                this.resultMessage = 'Parties (plaintif) have been attached'
                this.$notify({
                  group: 'message',
                  title: 'Notification',
                  type: 'success',
                  text: this.resultMessage
                })
              })
            }

            if (this.defendantList.length > 0) {
              this.defendantList = this.defendantList.map(v => {
                const { id, ...rest } = v
                return { ...rest, caseId: this.criminalCase.id }
              })

              CaseService.insertPartyBatch(this.defendantList).then(
                response => {
                  this.resultMessage = 'Parties (defendants) have been attached'
                  this.$notify({
                    group: 'message',
                    title: 'Notification',
                    type: 'success',
                    text: this.resultMessage
                  })
                }
              )
            }

            ServicePolice.updateTransferStatus(this.$route.params.id).then(
              response => {
                this.resultMessage = 'Case has been moved from Police to Court'
                this.$notify({
                  group: 'message',
                  title: 'Notification',
                  type: 'success',
                  text: this.resultMessage
                })
              }
            )

            this.$router.push({ name: 'criminal-cases' })
          }
        },
        error => {
          this.resultMessage = error.response.data
          this.$notify({
            group: 'message',
            title: 'Notification',
            type: 'error',
            text: this.resultMessage
          })
        }
      )
    },
    openPlaintiffDialog() {
      this.plaintiffDialog = true
    },
    openDefendantDialog() {
      this.defendantDialog = true
    },
    addPlaintiff() {
      this.plaintifList.push({ partyRole: { id: 1 } })
    },
    addDefendant() {
      this.defendantList.push({ partyRole: { id: 2 } })
    },
    removePlaintiff(index) {
      this.$confirm({
        message: 'Do you want to delete this row: ' + index,
        button: {
          no: 'No',
          yes: 'Yes'
        },
        callback: confirm => {
          if (confirm) {
            this.plaintifList.splice(index, 1)
          }
        }
      })
    }
  }
}
</script>
